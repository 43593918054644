import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'gatsby';
import { NavigationCard } from '@entur/layout';
import { ButtonGroup, SecondaryButton, SuccessButton } from '@entur/button';
import { ForwardIcon } from '@entur/icons';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const BannerAlertBox = makeShortcode("BannerAlertBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "widgets-deprecated"
    }}>{`Widgets (deprecated)`}</h1>
    <BannerAlertBox title="Widgets are shutting down August 1st" variant="warning" mdxType="BannerAlertBox">
    <p>
        We are shutting our Widget service. Existing widgets will be supported
        until August 1st 2024, and you can no longer create new widgets. We are
        focusing our efforts on developing other services, and we recommend all
        our users to replace widgets with
        <a href="/pages-travel-link"> Travel link</a>
    </p>
    </BannerAlertBox>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      